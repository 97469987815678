<template>
  <div class="app-container" v-loading="this.isLoading">
    <el-form ref="form" label-width="300px">
      <el-button type="primary" @click="onExportData()">
        Export data
      </el-button>

      <MyFormItem label="Time Begin" tooltip="Time Begin">
        <el-date-picker
            v-model="inputTimeStart"
            type="datetime"
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>

      <MyFormItem label="Time End" tooltip="Time End">
        <el-date-picker
            v-model="inputTimeEnd"
            type="datetime"
            value-format="timestamp"
            placeholder="Select date and time"
        >
        </el-date-picker>
      </MyFormItem>

      <MyFormItem label="Game Version" tooltip="Game Version">
        <el-input class="form-input" v-model="inputValue.gameVersion" placeholder="Example: 1.65.0" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="Country" tooltip="Country">
        <el-input class="form-input" v-model="inputValue.country" placeholder="" size="normal" clearable/>
      </MyFormItem>

      <MyFormItem label="Star >=" tooltip="Star >=">
        <el-input-number v-model="inputValue.startStart" size="normal" label="label" :step="1" :controls="true" :min="1" :max="5"/>
      </MyFormItem>

      <MyFormItem label="Star <=" tooltip="Star <=">
        <el-input-number v-model="inputValue.startEnd" size="normal" label="label" :step="1" :controls="true" :min="1" :max="5"/>
      </MyFormItem>

      <el-button type="primary" @click="onTapSearch()">
        Search
      </el-button>

      <br/>

      <el-pagination
          background layout="prev, pager, next"
          :total="retValue.total"
          :page-size="retValue.limit"
          :current-page="retValue.page"
          @current-change="onPageChange"
          v-if="retValue.listData.length > 0"
      />
      <el-table :data="retValue.listData" border v-if="retValue.listData.length > 0">
        <el-table-column type="expand">
          <template #default="props">
            <div m="4">
              <p m="t-0 b-2"> Sent From: {{ props.row.SentFrom }} </p>
              <p m="t-0 b-2"> User ID: {{ props.row.UserId }} </p>
              <p m="t-0 b-2"> FBID: {{ props.row.FbId }} </p>
              <p m="t-0 b-2"> GID: {{ props.row.GId }} </p>
              <p m="t-0 b-2"> Country: {{ props.row.Country }} </p>
              <p m="t-0 b-2"> Language: {{ props.row.Language }} </p>
              <p m="t-0 b-2"> Os Ver: {{ props.row.OSVer }} </p>
              <p m="t-0 b-2"> Game Version: {{ props.row.GameVersion }} </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="Star"
            label="Rating"
            width="100"
        />
        <el-table-column
            prop="FeedBack"
            label="Note"
        />
      </el-table>
    </el-form>
  </div>
</template>

<script>

import moment from "moment";

import MyFormItem from "@/views/loop-machine/component/myFormItem.vue"
import {Message} from "element-ui";
import request from "@/utils/request-service-base";
import DownloadFile from '@/helpers/downloadFile'

export default {
  name: "rating",
  components: {MyFormItem},
  data() {
    return {
      isLoading: false,
      inputValue: {
        timeStart: moment().unix(),
        timeEnd: moment().unix(),
        gameVersion: "1.69.0",
        country: "",
        startStart: 1,
        startEnd: 4,
        currentPage: 1,
      },
      retValue: {
        listData: [],
        total: 0,
        page: 0,
        limit: 0,
      }
    }
  },
  computed: {
    inputTimeStart: {
      get: function() {
        return this.inputValue.timeStart * 1000;
      },
      set: function (value) {
        this.inputValue.timeStart = value / 1000;
      }
    },
    inputTimeEnd: {
      get: function() {
        return this.inputValue.timeEnd * 1000;
      },
      set: function (value) {
        this.inputValue.timeEnd = value / 1000;
      }
    },
  },
  methods: {
    async filterWithServer() {
      try {
        this.isLoading = true;

        let dataRequest = {
          TimeBegin: this.inputValue.timeStart,
          TimeEnd: this.inputValue.timeEnd,
          GameVersion: this.inputValue.gameVersion,
          Country: this.inputValue.country,
          StarBegin: this.inputValue.startStart,
          StarEnd: this.inputValue.startEnd,
        };

        let response = await request({
          url: `/maintain/user-rating/filter-rate?page=${this.inputValue.currentPage}`,
          method: `post`,
          data: dataRequest
        });

        let dataResponse = response.data;

        this.retValue.listData = dataResponse.data;
        this.retValue.total = dataResponse.paging.total;
        this.retValue.limit = dataResponse.paging.limit;
        this.retValue.page = parseInt(dataResponse.paging.page);

        this.inputValue.currentPage = this.retValue.page;

        this.isLoading = false;
      }
      catch (ex) {
        Message({
          message: `Error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });

        this.isLoading = false;
      }
    },

    async onTapSearch(){
      this.inputValue.currentPage = 1;
      await this.filterWithServer();
    },

    async onPageChange(val) {
      this.inputValue.currentPage = val;
      await this.filterWithServer();
    },

    async onExportData() {
      try {
        this.isLoading = true;

        let dataRequest = {
          TimeBegin: this.inputValue.timeStart,
          TimeEnd: this.inputValue.timeEnd,
          GameVersion: "",
          Country: "",
          StarBegin: 1,
          StarEnd: 5,
        };

        let beginPage = 1;
        let isBreak = false;

        let tsvString = "Date\tUserID\tRating\tFeedBack\tGameVersion\tCountry\tDeviceModel\n";

        while (!isBreak) {
          let response = await request({
            url: `/maintain/user-rating/filter-rate?page=${beginPage}`,
            method: `post`,
            data: dataRequest
          });

          let dataResponse = response.data;

          for (let i = 0; i < dataResponse.data.length; i++) {
            let data = dataResponse.data[i];
            let time = `${moment.unix(data.Time).local().format("DD-MM-YYYY HH:mm:ss")}`;
            tsvString += `${time}\t${data.UserId}\t${data.Star}\t${data.FeedBack}\t${data.GameVersion}\t${data.Country}\t${data.SentFrom}\n`;
          }

          let page = parseInt(dataResponse.paging.page);
          let total = parseInt(dataResponse.paging.total);
          let limit = parseInt(dataResponse.paging.limit);

          if (page * limit >= total) {
            isBreak = true;
          }
          else {
            beginPage++;
          }
        }

        DownloadFile("Rating.tsv", tsvString);

        this.isLoading = false;
      }
      catch (ex) {
        Message({
          message: `Error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });

        this.isLoading = false;
      }

    },
  }
};
</script>

<style lang="scss" scoped>
</style>
